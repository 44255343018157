

































import { computed, defineComponent, PropType } from "@vue/composition-api";

import { useLocations } from "@/lib/locations";

import LocationItem from "./Location.vue";
import { FieldsConfig } from "./Form.vue";
import { LocationType } from "@/types/locations/location";

export default defineComponent({
  props: {
    types: {
      type: Array as PropType<LocationType[]>,
      default: () => Object.values(LocationType)
    },
    create: { type: Boolean, default: false },
    createMissing: { type: Boolean, default: false },
    fields: { type: Object as PropType<FieldsConfig> }
  },
  components: { LocationItem },
  setup({ types: _types, createMissing: _createMissing }) {
    const { get, getAll, filterByType, list } = useLocations();
    get();
    const types = computed(() => [undefined, ..._types]);
    const locations = computed(() => filterByType(getAll(), ...types.value));
    const loading = computed(() => !!list()?.loading);
    const missingTypes = computed(() => {
      if (loading.value || !_createMissing) return [];
      return _types.filter(
        type => filterByType(locations.value, type).length === 0
      );
    });
    return { locations, loading, missingTypes };
  }
});
