












































import { computed, defineComponent } from "@vue/composition-api";

import LocationsList from "@/components/settings/locations/List.vue";
import { LocationType } from "@/types/locations/location";
import { isEmbedded } from "@/services/embedded";
import { Routes } from "@/router/types";

export default defineComponent({
  components: { LocationsList },
  setup() {
    const embedded = computed(() => isEmbedded());
    return { LocationType, embedded, Routes };
  }
});
